import React from "react";
import { Router, BrowserRouter, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { history } from "../_helpers";
import { alertActions } from "../_actions";
import { PrivateRoute } from "../_components";
import { Navigation } from "../_components";
import { routes } from "../_constants";
import { News } from "../News";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import CookieBanner from "react-cookie-banner";

class App extends React.Component {
    constructor(props) {
        super(props);
        const { dispatch } = this.props;

        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
    }

    render() {
        const { alert } = this.props;

        const containerStyles = {
            padding: "10px",
        };
        return (
            <div className="displayContainer">
                <CookieBanner
                    message="Yes, we use cookies. If you don't like it change website, we won't miss you!"
                    onAccept={() => {}}
                    cookie="user-has-accepted-cookies"
                />
                <Navigation />
                <div className="innerContainer" style={containerStyles}>
                    {alert.message && (
                        <div className={`alert ${alert.type}`}>
                            {alert.message}
                        </div>
                    )}
                    <Router history={history}>
                        {routes.map((route, key) => {
                            return route.private ? (
                                <PrivateRoute
                                    key={key}
                                    exact={route.exact ? true : false}
                                    path={route.path}
                                    component={route.component}
                                />
                            ) : (
                                <Route
                                    key={key}
                                    exact={route.exact ? true : false}
                                    path={route.path}
                                    component={route.component}
                                />
                            );
                        })}
                    </Router>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}

const connectedApp = connect(mapStateToProps)(withRouter(App));
export { connectedApp as App };
