import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import { fishService, speciesService } from "../_services";
import { history } from "../_helpers";
import { faCheck, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormButtons } from "../_components/Form/FormButtons";

class EditFish extends React.Component {
    constructor(props) {
        super(props);

        var now = new Date();
        const timestring = `${now.getFullYear()}-${`${
            now.getMonth() + 1
        }`.padStart(2, 0)}-${`${now.getDate() + 1}`.padStart(
            2,
            0
        )}T${`${now.getHours()}`.padStart(
            2,
            0
        )}:${`${now.getMinutes()}`.padStart(2, 0)}`;

        const { profile } = this.props;

        this.state = {
            id: this.props.location.state.fish?.id ?? null,
            species: this.props.location.state.fish?.species_id ?? -1,
            speciesList: [],
            catched: this.props.location.state.fish?.catched ?? timestring,
            trip: this.props.location.state.fish?.trip_id,
            imageUrl: this.props.location.state.fish?.imageUrl,
            submitted: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onFishDelete = this.onFishDelete.bind(this);
    }

    componentDidMount() {
        speciesService.getAll().then((species) => {
            const speciesList = species;
            this.setState({ speciesList: speciesList });
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onFishDelete(e) {
        e.preventDefault();
        const { id } = this.state;

        fishService.deleteById(id).then(() => {
            history.push(this.props.location.state?.backUrl ?? "/");
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { id, species, catched, trip } = this.state;
        const { dispatch } = this.props;
        if ((id, species > 0, catched, trip))
            fishService.create(id, species, catched, trip).then(() => {
                history.push(this.props.location.state.backUrl ?? "/");
            });
    }

    render() {
        const { id, species, catched, trip, submitted, speciesList } =
            this.state;
        return (
            <div>
                <h2>Fish </h2>
                <form name="form" onSubmit={this.handleSubmit}>
                    <div
                        className={
                            "form-group" +
                            (submitted && !species ? " has-error" : "")
                        }
                    >
                        <label htmlFor="species">Species</label>
                        <select
                            className="form-select"
                            aria-label="select species"
                            name="species"
                            value={species}
                            onChange={this.handleChange}
                        >
                            <option value="-1" disabled>
                                Select Species
                            </option>
                            {speciesList.map((el,key ) => (
                                <option key={key} name={el.name} id={el.id} value={el.id}>
                                    {el.name}
                                </option>
                            ))}
                        </select>
                        {submitted && !species && (
                            <div className="help-block">
                                Species is required
                            </div>
                        )}
                    </div>
                    <div
                        className={
                            "form-group" +
                            (submitted && !species ? " has-error" : "")
                        }
                    >
                        <label htmlFor="catched">Catched</label>
                        <input
                            type="datetime-local"
                            className="form-control"
                            name="catched"
                            value={catched}
                            onChange={this.handleChange}
                        />
                        {submitted && !catched && (
                            <div className="help-block">
                                Catched is required
                            </div>
                        )}
                    </div>

                    <FormButtons
                        showSave
                        showDelete
                        onDeleteClick={this.onFishDelete}
                    />
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { profile } = state;
    return {
        profile,
    };
}

const connectedEditFish = connect(mapStateToProps)(withRouter(EditFish));
export { connectedEditFish as EditFish };
