import { userConstants } from "../_constants";

let storedProfile = JSON.parse(localStorage.getItem("profile"));

const initialState = storedProfile ? storedProfile : {};

export function profile(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_SUCCESS:
            return action.user.user ?? {};
        case userConstants.PROFILE_SUCCESS:
            return action.user.user ?? {};
        case userConstants.PROFILE_FAILURE:
        case userConstants.LOGOUT:
            return {};
        default:
            return state;
    }
}
