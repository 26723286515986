import { authHeader } from "../_helpers";

export const tripService = {
    create,
    getAllOfUser,
    deleteById,
    getById
};

function create(id, name, location_id, started, stopped, user_id, lat, lng) {
    const requestOptions = {
        method: "POST",
        headers: authHeader({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({ id, name, location_id, started, stopped, user_id, lat, lng }),
    };

    return fetch(`${process.env.MIX_API_ENDPOINT}/trip/create`, requestOptions)
        .then(handleResponse)
        .then((trip) => {
            return trip;
        });
}

function getAllOfUser() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.MIX_API_ENDPOINT}/trip/getAllOfUser`, requestOptions).then(handleResponse);
}


function deleteById(id) {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({ id }),
    };

    return fetch(`${process.env.MIX_API_ENDPOINT}/trip/delete`, requestOptions)
        .then(handleResponse)
        .then((fish) => {
            return fish;
        });
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader({ 'Content-Type': 'application/json' })

    };

    return fetch(`${process.env.MIX_API_ENDPOINT}/trip/getById/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
