import React from "react";
import { connect } from "react-redux";

import { tripService } from "../_services";
import { Trip } from ".";
import { history } from "../_helpers";

class TripOverview extends React.Component {
    constructor(props) {
        super(props);

        this.onTripClick = this.onTripClick.bind(this);
    }
    state = {
        trips: false,
    };

    componentDidMount() {
        tripService.getAllOfUser().then((trips) => {
            this.setState({ trips: trips });
        });
    }

    onTripClick(e) {
        history.push(`/showTrip/${e.id}`, { trip: e });
    }

    render() {
        const { profile } = this.props;
        const { trips } = this.state;
        return (
            <div>
                {!trips && <em>Loading trips...</em>}
                {trips && (
                    <>
                        <h3>{trips.length} Trips</h3>
                        {trips
                            .sort((first, second) => {
                                return (
                                    (first.stopped ?? new Date()) >
                                    (second.stopped ?? new Date())
                                );
                            })
                            .map((element, key) => (
                                <Trip
                                    key={key}
                                    trip={element}
                                    onClick={this.onTripClick}
                                />
                            ))}
                    </>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { profile } = state;
    return {
        profile,
    };
}

const connectedTripOverview = connect(mapStateToProps)(TripOverview);
export { connectedTripOverview as TripOverview };
