import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFish } from "@fortawesome/free-solid-svg-icons";
import { history } from "../_helpers";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const mapStyles = {
    width: "100%",
    height: "200px",
    position: "relative",
};
class Trip extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div
                className="card"
                style={{ margin: "20px 0" }}
                onClick={(e) => {
                    this.props.onClick(this.props.trip);
                }}
            >
                <Map
                    google={this.props.google}
                    zoom={14}
                    style={mapStyles}
                    containerStyle={{
                        position: "relative",
                    }}
                    ref={this.refMap}
                    initialCenter={{
                        lat: this.props.trip.lat,
                        lng: this.props.trip.lng,
                    }}
                    draggable={false}
                    fullscreenControl={false}
                    scaleControl={false}
                    zoomControl={false}
                >
                    <Marker
                        mapCenter
                        position={{
                            lat: this.props.trip.lat,
                            lng: this.props.trip.lng,
                        }}
                    />
                </Map>
                <div className="d-flex  bd-highlight ">
                    <div className="p-2 flex-fill bd-highlight">
                        {this.props.trip.name}
                    </div>
                </div>
                <div className="d-flex  bd-highlight">
                    <div className="p-2 flex-fill bd-highlight">
                        <h6>Start:</h6>
                        <span style={{ fontSize: "10px" }}>
                            {this.props.trip.started}
                        </span>
                    </div>

                    <div className="p-2 flex-fill bd-highlight">
                        <h6>Stop:</h6>
                        <span style={{ fontSize: "10px" }}>
                            {this.props.trip.stopped}
                        </span>
                    </div>
                </div>
                <div className="d-flex  bd-highlight">
                    <div className="p-2 flex-fill bd-highlight">
                        Catches {this.props.trip.catches.length}
                    </div>
                    <div className="p-2 bd-highlight">
                        <Link
                            id="test"
                            to={`/newFish/${this.props.trip.id}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                history.push(`/newFish/${this.props.trip.id}`);
                            }}
                            style={{ zIndex: "99" }}
                        >
                            <FontAwesomeIcon icon={faFish} size="2x" />
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
const connectedTrip = GoogleApiWrapper({
    apiKey: "",
})(Trip);
export { connectedTrip as Trip };
