import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFish,
    faEdit,
    faSpinner,
    faPen,
} from "@fortawesome/free-solid-svg-icons";
import { history } from "../_helpers";
import { tripService } from "../_services";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const mapStyles = {
    width: "100%",
    paddingTop: "100%",
    position: "relative",
};

class TripExtended extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            trip: false,
        };
    }

    componentDidMount() {
        tripService.getById(this.props.match.params.tripId).then((trip) => {
            this.setState({ trip: trip });
        });
    }

    render() {
        const { trip } = this.state;
        return (
            <>
                {!trip && (
                    <FontAwesomeIcon
                        size="10x"
                        icon={faSpinner}
                        pulse
                        className="center"
                    />
                )}
                {trip && (
                    <>
                        <div className="card">
                            <Map
                                google={this.props.google}
                                zoom={14}
                                style={mapStyles}
                                containerStyle={{
                                    position: "relative",
                                }}
                                ref={this.refMap}
                                initialCenter={{
                                    lat: trip.lat,
                                    lng: trip.lng,
                                }}
                            >
                                <Marker
                                    mapCenter
                                    position={{
                                        lat: trip.lat,
                                        lng: trip.lng,
                                    }}
                                />
                            </Map>
                            <div className="d-flex  bd-highlight">
                                <div className="p-2 flex-fill bd-highlight">
                                    <h6>Start:</h6> <br />
                                    <span style={{ fontSize: "10px" }}>
                                        {trip.started}
                                    </span>
                                </div>

                                <div className="p-2 flex-fill bd-highlight">
                                    <h6>Stop:</h6> <br />
                                    <span style={{ fontSize: "10px" }}>
                                        {trip.stopped}
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex  bd-highlight ">
                                <div className="p-2 flex-fill bd-highlight">
                                    {trip.name}
                                </div>
                            </div>
                            <div className="d-flex  bd-highlight">
                                <div className="p-2 flex-fill bd-highlight">
                                    Catches {trip.catches.length}
                                </div>
                                <div className="p-2 bd-highlight">
                                    <Link
                                        id="test"
                                        to={{
                                            pathname: `/newFish/${trip.id}`,
                                            state: {
                                                backUrl:
                                                    this.props.location
                                                        .pathname,
                                            },
                                        }}
                                        style={{ zIndex: "99" }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faFish}
                                            size="2x"
                                        />
                                    </Link>
                                </div>
                                <div className="p-2 bd-highlight">
                                    <Link
                                        id="test"
                                        to={`/editTrip/${trip.id}`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            history.push(
                                                `/editTrip/${trip.id}`
                                            );
                                        }}
                                        style={{ zIndex: "99" }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faPen}
                                            size="2x"
                                        />
                                    </Link>
                                </div>
                            </div>
                            {trip.catches.map((fish) => {
                                return (
                                    <div
                                        className="card"
                                        onClick={() => {
                                            history.push(
                                                `/editFish/${trip.id}`,
                                                {
                                                    backUrl:
                                                        this.props.location
                                                            .pathname,
                                                    fish: fish,
                                                }
                                            );
                                        }}
                                    >
                                        <div className="d-flex  bd-highlight">
                                            {fish.imageUrl && (
                                                <div className="p-2 bd-highlight">
                                                    <img
                                                        src={`/storage/get?filename=${fish.imageUrl}`}
                                                        style={{
                                                            maxHeight: "80px",
                                                            maxWidth: "80px",
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <div className="p-2 flex-fill bd-highlight">
                                                <h6>Art:</h6> <br />
                                                <span
                                                    style={{
                                                        fontSize: "10px",
                                                    }}
                                                >
                                                    {fish.species?.name}
                                                </span>
                                            </div>

                                            <div className="p-2 flex-fill bd-highlight">
                                                <h6>Am:</h6> <br />
                                                <span
                                                    style={{
                                                        fontSize: "10px",
                                                    }}
                                                >
                                                    {fish.created_at}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </>
        );
    }
}
const connectedTripExtended = GoogleApiWrapper({
    apiKey: "",
})(TripExtended);
export { connectedTripExtended as TripExtended };
