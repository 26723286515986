import React from "react";
import { connect } from "react-redux";

import { tripService, fishService, userService } from "../_services";
import moment from "moment";
import PieChart, {
    Series,
    Label,
    Connector,
    Size,
    Export,
} from "devextreme-react/pie-chart";

class Overview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            trips: [],
            locations: [],
            catches: [],
        };
        this.dataGridRefLocations = React.createRef();
        this.dataGridRefCatches = React.createRef();
    }

    componentDidMount() {
        tripService.getAllOfUser().then((trips) => {
            var locations = [];
            trips.forEach((trip) => {
                if (locations[trip.location.id] === undefined) {
                    locations[trip.location.id] = {
                        name: trip.location.name,
                        count: 0,
                    };
                }
                locations[trip.location.id].count++;
            });
            this.setState({ trips: trips, locations: locations });
            const dataSource =
                this.dataGridRefLocations.current.instance.getDataSource();
            dataSource.load();
        });
        fishService.getAllOfUser().then((fishes) => {
            var catches = [];
            fishes.forEach((fish) => {
                if (fish.species) {
                    if (catches[fish.species.id] === undefined) {
                        catches[fish.species.id] = {
                            name: fish.species.name,
                            count: 0,
                        };
                    }
                    catches[fish.species.id].count++;
                }
            });
            this.setState({ catches: catches });
            const dataSource =
                this.dataGridRefCatches.current.instance.getDataSource();
            dataSource.load();
        });
    }

    render() {
        const { profile } = this.props;
        const { trips, locations, catches } = this.state;
        return (
            <div>
                {profile.activeTrip && (
                    <>
                        <div className="card">
                            <h3>Aktueller Ausflug</h3>
                            <div className="d-flex  bd-highlight">
                                <div className="p-2 flex-fill bd-highlight">
                                    Seit &nbsp;
                                    <span>
                                        {moment(
                                            profile.activeTrip.started
                                        ).fromNow()}
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex  bd-highlight">
                                <div className="p-2 flex-fill bd-highlight">
                                    Fänge:
                                    <span>
                                        {profile.activeTrip.catches.length}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {!profile.activeTrip && profile.lastTrip && (
                    <>
                        <div className="card">
                            <h3>letzter Ausflug</h3>
                            <div className="d-flex  bd-highlight">
                                <div className="p-2 flex-fill bd-highlight">
                                    <span>
                                        {moment(
                                            profile.lastTrip.stopped
                                        ).fromNow()}{" "}
                                        &nbsp; her
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex  bd-highlight">
                                <div className="p-2 flex-fill bd-highlight">
                                    Dauer:
                                    <span>
                                        {moment(profile.lastTrip.stopped)
                                            .subtract(
                                                moment(profile.lastTrip.started)
                                            )
                                            .format("hh:mm")}
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex  bd-highlight">
                                <div className="p-2 flex-fill bd-highlight">
                                    Fänge:
                                    <span>
                                        {profile.lastTrip.catches.length}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {trips && (
                    <>
                        <div className="card">
                            <h3>Allgemein</h3>
                            <div className="d-flex  bd-highlight">
                                <div className="p-2 flex-fill bd-highlight">
                                    <span>{trips.length} Ausflüge</span>
                                </div>
                            </div>
                        </div>
                        <PieChart
                            id="locationPie"
                            dataSource={locations}
                            palette="Bright"
                            title="Location"
                            ref={this.dataGridRefLocations}
                        >
                            <Series argumentField="name" valueField="count">
                                <Label visible={true}>
                                    <Connector visible={true} width={1} />
                                </Label>
                            </Series>

                            <Size width="100%" />
                        </PieChart>
                        <PieChart
                            id="catchesPie"
                            dataSource={catches}
                            palette="Bright"
                            title="Catches"
                            ref={this.dataGridRefCatches}
                        >
                            <Series argumentField="name" valueField="count">
                                <Label visible={true}>
                                    <Connector visible={true} width={1} />
                                </Label>
                            </Series>

                            <Size width="100%" />
                        </PieChart>
                    </>
                )}
                {!profile.activeTrip && !profile.lastTrip && "No data recorded"}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { profile } = state;
    return {
        profile,
    };
}

const connectedOverview = connect(mapStateToProps)(Overview);
export { connectedOverview as Overview };
