import { News } from "../News";
import { Overview } from "../Overview";
import { Login, Register } from "../Login";
import { TripCreator, TripExtended, TripOverview } from "../Trip";
import { Fish, EditFish } from "../Fish";
import {Statistics} from "../Statistics";

export const routes = [
    { private:true, exact: true, path: "/", component: Overview },
    { path: "/news", component: News },
    { private:true, path: "/statistics", component: Statistics },
    { path: "/login", component: Login },
    { path: "/register", component: Register },
    { private: true, path: "/trips", component: TripOverview },
    { private: true, path: "/newTrip", component: TripCreator },
    { private: true, path: "/showTrip/:tripId", component: TripExtended },
    { private: true, path: "/editTrip/:tripId", component: TripCreator },
    { private: true, path: "/newFish/:tripId", component: Fish },
    { private: true, path: "/editFish/:fishId", component: EditFish },
];
