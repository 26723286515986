import React, { Component } from "react";
import { connect } from "react-redux";
import { Navbar, Nav } from "react-bootstrap";
import "../../../css/app.css";
import { userActions } from "../_actions";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faFish,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faChartBar,
    faCloudSun,
} from "@fortawesome/free-solid-svg-icons";
import { faChartBar as faChartBarRegular } from "@fortawesome/free-regular-svg-icons";
import { history } from "../_helpers";

class Navigation extends Component {
    constructor(props) {
        super(props);

        this.logOut = this.logOut.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(userActions.profile());
    }

    logOut(e) {
        e.preventDefault();
        this.props.dispatch(userActions.logout());
    }

    render() {
        const { profile } = this.props;
        const loggedIn = profile ? (profile.id ? true : false) : false;

        return (
            <>
                <Navbar
                    collapseOnSelect
                    expand="lg"
                    bg="dark"
                    variant="dark"
                    style={{ padding: "0.5rem", zIndex: 99 }}
                    fixed="top"
                >
                    <Navbar.Brand href="#home" style={{ margin: "0 0 0 20px" }}>
                        Fish Log
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbar-collapse" />
                    <Navbar.Collapse
                        id="responsive-navbar-nav"
                        className="bg-dark"
                    >
                        <Nav>
                            <Nav.Link href="/">Home</Nav.Link>
                            {loggedIn ? (
                                <>
                                    <Nav.Link href="/trips">Trips</Nav.Link>
                                    <Nav.Link href="/statistics">
                                        Statistics
                                    </Nav.Link>
                                    <Nav.Link href="/prediction">
                                        Prediction
                                    </Nav.Link>
                                </>
                            ) : (
                                <></>
                            )}
                            <Nav.Link href="/about">Über</Nav.Link>
                            {/* <NavDropdown
                                title="Dropdown"
                                id="collasible-nav-dropdown"
                            >
                                <NavDropdown.Item href="#action/3.1">
                                    Action
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">
                                    Another action
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">
                                    Something
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">
                                    Separated link
                                </NavDropdown.Item>
                            </NavDropdown> */}
                        </Nav>
                        <Nav className="ms-auto">
                            {loggedIn ? (
                                <>
                                    <Nav.Link
                                        href="/logout"
                                        onClick={this.logOut}
                                    >
                                        Logout
                                    </Nav.Link>
                                </>
                            ) : (
                                <>
                                    <Nav.Link href="/login">Login</Nav.Link>
                                    <Nav.Link href="/register">SignUp</Nav.Link>
                                </>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <Navbar
                    id="navbar_bottom"
                    collapseOnSelect
                    expand="lg"
                    bg="dark"
                    variant="dark"
                    style={{ zIndex: 99 }}
                    fixed="bottom"
                >
                    <Nav>
                        <Nav.Link
                            className="flex-fill"
                            style={{ textAlign: "center" }}
                            href="/trips"
                        >
                            <FontAwesomeIcon icon={faMapMarkedAlt} />
                        </Nav.Link>
                        <Nav.Link
                            className="flex-fill"
                            style={{ textAlign: "center" }}
                            href="/statistics"
                        >
                            <FontAwesomeIcon icon={faChartBarRegular} />
                        </Nav.Link>
                        <Nav.Link
                            className="flex-fill"
                            style={{ textAlign: "center" }}
                            href="/prediction"
                        >
                            <FontAwesomeIcon icon={faCloudSun} />
                        </Nav.Link>
                    </Nav>
                </Navbar>
                {loggedIn ? (
                    <Fab
                        mainButtonStyles={{
                            backgroundColor: "#00b5ad",
                        }}
                        style={{
                            bottom: 10,
                            right: 10,
                        }}
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        event="click"
                        alwaysShowTitle={true}
                    >
                        {profile?.activeTrip ? (
                            <>
                                <Action
                                    text="Fish"
                                    onClick={() =>
                                        history.push(
                                            `/newFish/${profile.activeTrip.id}`
                                        )
                                    }
                                >
                                    <FontAwesomeIcon icon={faFish} />
                                </Action>
                                <Action
                                    text="Session"
                                    onClick={() =>
                                        history.push(
                                            `/editTrip/${profile.activeTrip.id}`
                                        )
                                    }
                                    style={{ backgroundColor: "red" }}
                                >
                                    <FontAwesomeIcon icon={faMapMarkedAlt} />
                                </Action>
                            </>
                        ) : (
                            <>
                                <Action
                                    text="Session"
                                    onClick={() => history.push("/newTrip")}
                                >
                                    <FontAwesomeIcon icon={faMapMarkedAlt} />
                                </Action>
                            </>
                        )}
                    </Fab>
                ) : (
                    <></>
                )}
            </>
        );
    }
}

function mapStateToProps(state) {
    const { profile } = state;
    return {
        profile,
    };
}

const connectedNavigation = connect(mapStateToProps)(Navigation);
export { connectedNavigation as Navigation };
