import React from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import { tripService, locationService } from "../_services";
import { history } from "../_helpers";
import { times } from "lodash-es";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { userActions } from "../_actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FormButtons } from "../_components/Form/FormButtons";
import moment from "moment";

const mapStyles = {
    width: "100%",
    paddingTop: "100%",
    position: "relative",
};

const CustomInput = ({
    type,
    name,
    submitted,
    property,
    description,
    handleChange,
    children,
}) => (
    <div
        className={"form-group" + (submitted && !property ? " has-error" : "")}
    >
        <label htmlFor={name}>{description}</label>
        <input
            type={type}
            className="form-control"
            name={name}
            value={property}
            onChange={handleChange}
        >
            {children}
        </input>
        {submitted && !property && (
            <div className="help-block">{description} is required</div>
        )}
    </div>
);

const timestringNow = moment().format("YYYY-MM-DDTHH:mm");

class TripCreator extends React.Component {
    constructor(props) {
        super(props);

        const { profile } = this.props;

        this.refMap = React.createRef();

        this.state = {
            loading: this.props.match.params.tripId ? true : false,
            id: null,
            name: timestringNow,
            location: -1,
            locationList: [],
            started: timestringNow,
            stopped: "",
            user: this.props.profile.id,
            submitted: false,
            latLng: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.updatePosition = this.updatePosition.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.deleteTrip = this.deleteTrip.bind(this);
        this.handleBoundsChanged = this.handleBoundsChanged.bind(this);

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) =>
                this.updatePosition(position)
            );
        } else {
            this.updatePosition({ coords: { latitude: 0, longitude: 0 } });
        }
    }

    componentDidMount() {
        const { loading } = this.state;
        locationService.getAll().then((locations) => {
            const locationList = locations;
            this.setState({ locationList: locationList });
        });
        if (loading) {
            tripService.getById(this.props.match.params.tripId).then((trip) => {
                this.setState({
                    id: trip.id,
                    name: trip.name,
                    location: trip.location_id,
                    started: moment(trip.started).format("YYYY-MM-DDTHH:mm"),
                    stopped: trip.stopped
                        ? moment(trip.stopped).format("YYYY-MM-DDTHH:mm")
                        : timestringNow,
                    latLng: { lat: trip.lat, lng: trip.lng },
                    loading: false,
                });
            });
        }
    }

    deleteTrip(e){
         e.preventDefault();
        const { id } = this.state;

        tripService.deleteById(id).then(() => {
            history.push("/trips");
        });
    }
     updatePosition(position) {
        this.setState({
            latLng: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            },
        });
    }
    handleBoundsChanged() {
        if (this.refMap.current && this.refMap.current.map.center) {
            const mapCenter = {
                lat: this.refMap.current.map.center.lat(),
                lng: this.refMap.current.map.center.lng(),
            };
            this.setState({ latLng: mapCenter });
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { id, name, location, started, stopped, user, profile, latLng } =
            this.state;
        const { dispatch } = this.props;
        if (
            name &&
            location > 0 &&
            started &&
            user &&
            (!profile?.activeTrip || stopped)
        )
            tripService
                .create(
                    id,
                    name,
                    location,
                    started,
                    stopped == "" ? null : stopped,
                    user,
                    latLng.lat,
                    latLng.lng
                )
                .then(() => {
                    this.props.dispatch(userActions.profile());
                    history.push(this.props.location.state?.backUrl ?? "/trips");
                });
    }

    render() {
        const { profile } = this.props;
        const loggedIn = profile.id ? true : false;

        const {
            id,
            name,
            location,
            started,
            stopped,
            submitted,
            latLng,
            refMap,
            locationList,
            loading,
        } = this.state;
        return (
            <div>
                {loading && (
                    <FontAwesomeIcon
                        size="10x"
                        icon={faSpinner}
                        pulse
                        className="center"
                    />
                )}
                {!loading && (
                    <>
                        <h2>Trip "{name}"</h2>
                        <form name="form" onSubmit={this.handleSubmit}>
                            <CustomInput
                                type="text"
                                name="name"
                                submitted={submitted}
                                property={name}
                                description="Name"
                                handleChange={this.handleChange}
                            />
                            <div
                                className={
                                    "form-group" +
                                    (submitted && !location ? " has-error" : "")
                                }
                            >
                                <label htmlFor="location">Location</label>
                                <select
                                    className="form-select"
                                    name="location"
                                    value={location}
                                    onChange={this.handleChange}
                                >
                                    <option value="-1" disabled>
                                        Select Location
                                    </option>
                                    {locationList.map((el, key) => (
                                        <option
                                            key={key}
                                            name={el.name}
                                            id={el.id}
                                            value={el.id}
                                        >
                                            {el.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <CustomInput
                                type="datetime-local"
                                name="started"
                                submitted={submitted}
                                property={started}
                                description="Started"
                                handleChange={this.handleChange}
                            />
                            <CustomInput
                                type="datetime-local"
                                name="stopped"
                                submitted={submitted && id}
                                property={stopped}
                                description="Stopped"
                                handleChange={this.handleChange}
                            />
                            <div
                                className={
                                    "form-group" +
                                    (submitted && !stopped ? " has-error" : "")
                                }
                            >
                                {latLng && (
                                    <Map
                                        google={this.props.google}
                                        zoom={14}
                                        style={mapStyles}
                                        containerStyle={{
                                            position: "relative",
                                        }}
                                        initialCenter={latLng}
                                        ref={this.refMap}
                                        onBoundsChanged={
                                            this.handleBoundsChanged
                                        }
                                    >
                                        <Marker mapCenter position={latLng} />
                                    </Map>
                                )}
                            </div>
                            <FormButtons showSave showDelete onDeleteClick={this.deleteTrip}/>
                        </form>
                    </>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { profile } = state;
    return {
        profile,
    };
}

const connectedTripCreator = connect(mapStateToProps)(TripCreator);
const connectedGoogleTripCreator = GoogleApiWrapper({
    apiKey: "",
})(connectedTripCreator);
export { connectedGoogleTripCreator as TripCreator };
