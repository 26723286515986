import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { userActions } from "../_actions";

class News extends React.Component {
    render() {
        return <div>News</div>;
    }
}

function mapStateToProps(state) {
    return{

    }
}

const connectedHomePage = connect(mapStateToProps)(News);
export { connectedHomePage as News };
