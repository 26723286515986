import { userConstants } from "../_constants";
import { userService } from "../_services";
import { alertActions } from "./";
import { history } from "../_helpers";

export const userActions = {
    login,
    logout,
    register,
    getAll,
    profile
};

function login(username, password) {
    return (dispatch) => {
        dispatch(request({ username }));

        userService.login(username, password).then(
            (user) => {
                dispatch(success(user));
                history.push("/");
            },
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }
}

function register(username, email, password) {
    return (dispatch) => {
        dispatch(request({ username }));

        userService.register(username, email, password).then(
            (user) => {
                dispatch(success(user));
                userService.prof
                history.push("/login");
            },
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request(user) {
        return { type: userConstants.REGISTER_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.REGISTER_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.REGISTER_FAILURE, error };
    }
}

function logout() {
    return (dispatch) => {
        userService.logout().then((_) => {
            dispatch(success);
            history.push("/");
            history.go(0);
        });
    };
    function success() {
        return { type: userConstants.LOGOUT };
    }
}

function getAll() {
    return (dispatch) => {
        dispatch(request());

        userService.getAll().then(
            (users) => dispatch(success(users)),
            (error) => dispatch(failure(error))
        );
    };

    function request() {
        return { type: userConstants.GETALL_REQUEST };
    }
    function success(users) {
        return { type: userConstants.GETALL_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.GETALL_FAILURE, error };
    }
}

function profile() {
    return (dispatch) => {
        dispatch(request());

        userService.profile().then(
            (user) => {
                dispatch(success(user));
            },
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request(user) {
        return { type: userConstants.PROFILE_REQUEST };
    }
    function success(user) {
        return { type: userConstants.PROFILE_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.PROFILE_FAILURE, error };
    }
}
