import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import { fishService, speciesService } from "../_services";
import { history } from "../_helpers";
import { faCheck, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FormButtons } from "../_components/Form/FormButtons";
import { NoFishImage } from "./NoFish";

class Fish extends React.Component {
    constructor(props) {
        super(props);

        var now = new Date();
        const timestring = `${now.getFullYear()}-${`${
            now.getMonth() + 1
        }`.padStart(2, 0)}-${`${now.getDate() + 1}`.padStart(
            2,
            0
        )}T${`${now.getHours()}`.padStart(
            2,
            0
        )}:${`${now.getMinutes()}`.padStart(2, 0)}`;

        const { profile } = this.props;

        this.state = {
            id: null,
            species: -1,
            speciesList: [],
            catched: timestring,
            trip: this.props.match.params.tripId,
            submitted: false,
            image: null,
            previewImage: null,
        };

        this.imageInputRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
    }

    componentDidMount() {
        speciesService.getAll().then((species) => {
            const speciesList = species;
            this.setState({ speciesList: speciesList });
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onImageChange = (event) => {
        // if (event.target.files && event.target.files[0]) {
        //     let reader = new FileReader();
        //     var url = reader.readAsDataURL(event.target.files[0]);

        //     reader.onloadend = function (e) {
        //         this.setState({
        //             image: reader.result
        //         })
        //       }.bind(this);
        // }
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.setState({
                image: img,
                previewImage: URL.createObjectURL(img),
            });
        }
    };

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { id, species, catched, trip, image } = this.state;
        const { dispatch } = this.props;
        if ((id, species > 0, catched, trip))
            fishService.create(id, species, catched, trip, image).then(() => {
                history.push(this.props.location.state?.backUrl ?? "/");
            });
    }

    render() {
        const {
            id,
            species,
            catched,
            trip,
            submitted,
            speciesList,
            previewImage,
        } = this.state;
        return (
            <div>
                <h2>Fish </h2>
                <form name="form" onSubmit={this.handleSubmit}>
                    <div
                        className={
                            "form-group" +
                            (submitted && !species ? " has-error" : "")
                        }
                    >
                        {!previewImage && <NoFishImage onClick={()=>{this.imageInputRef.current.click();}}/>}
                        {previewImage &&
                        (
                            <img
                                src={previewImage}
                                alt="No Image"
                                style={{ maxWidth: "100%", maxHeight: "400px" }}
                                onClick={()=>{this.imageInputRef.current.click();}}
                            />
                        )}
                        <br/>

                        <input
                            hidden
                            className="form-control"
                            type="file"
                            name="image"
                            onChange={this.onImageChange}
                            ref={this.imageInputRef}
                        />
                        <label htmlFor="species">Species</label>
                        <select
                            className="form-select"
                            aria-label="select species"
                            name="species"
                            value={species}
                            onChange={this.handleChange}
                        >
                            <option value="-1" disabled>
                                Select Species
                            </option>
                            {speciesList.map((el, key) => (
                                <option
                                    key={key}
                                    name={el.name}
                                    id={el.id}
                                    value={el.id}
                                >
                                    {el.name}
                                </option>
                            ))}
                        </select>
                        {submitted && !species && (
                            <div className="help-block">
                                Species is required
                            </div>
                        )}
                    </div>
                    <div
                        className={
                            "form-group" +
                            (submitted && !species ? " has-error" : "")
                        }
                    >
                        <label htmlFor="catched">Catched</label>
                        <input
                            type="datetime-local"
                            className="form-control"
                            name="catched"
                            value={catched}
                            onChange={this.handleChange}
                        />
                        {submitted && !catched && (
                            <div className="help-block">
                                Catched is required
                            </div>
                        )}
                    </div>
                    <FormButtons showSave />
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { profile } = state;
    return {
        profile,
    };
}

const connectedFish = connect(mapStateToProps)(withRouter(Fish));
export { connectedFish as Fish };
