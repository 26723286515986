import { authHeader } from "../_helpers";

export const fishService = {
    create,
    getAllOfUser,
    deleteById
};

function create(id, species_id, catched_at, trip_id, image) {

    const formData  = new FormData  ();
    formData.append("id",id);
    formData.append("species_id",species_id);
    formData.append("catched_at",catched_at);
    formData.append("trip_id",trip_id);
    formData.append("image",image);

    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: formData,
    };

    return fetch(`${process.env.MIX_API_ENDPOINT}/fish/create`, requestOptions)
        .then(handleResponse)
        .then((trip) => {
            return trip;
        });
}

function deleteById(id) {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({ id }),
    };

    return fetch(`${process.env.MIX_API_ENDPOINT}/fish/delete`, requestOptions)
        .then(handleResponse)
        .then((fish) => {
            return fish;
        });
}

function getAllOfUser() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(
        `${process.env.MIX_API_ENDPOINT}/fish/getAllOfUser`,
        requestOptions
    ).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
